































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IUserProfile } from '@/interfaces';
import { readAdminUsers } from '@/store/admin/getters';
import { dispatchGetUsers } from '@/store/admin/actions';

@Component
export default class AdminUsers extends Vue {
  public headers = [
    {
      text: 'Nome Completo',
      sortable: true,
      value: 'full_name',
      align: 'left',
    },
    {
      text: 'E-mail',
      sortable: true,
      value: 'email',
      align: 'left',
    },
    {
      text: 'Ativo?',
      sortable: true,
      value: 'isActive',
      align: 'left',
    },
    {
      text: 'Admin?',
      sortable: true,
      value: 'isSuperuser',
      align: 'left',
    },
    {
      text: 'Ações',
      value: 'id',
    },
  ];
  get users() {
    return readAdminUsers(this.$store);
  }

  public async mounted() {
    await dispatchGetUsers(this.$store);
  }
}
